<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h1>{{this.plural}}</h1>
          <v-btn
              v-if="isAllowed('product','c')"
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            :to="`/${this.pluralLower}/create`"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-btn class="ml-2 mr-1" dark color="info" to="/productattributes">Attributes</v-btn>
          <v-btn class="mx-1" dark color="info" to="/producttags">Tags</v-btn>
          <v-btn class="mx-1" dark color="info" to="/productcategories">Categories</v-btn>
          <v-btn class="mx-1" dark color="info" to="/brands">Brands</v-btn>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <span>Load By:</span>
          <v-radio-group style="margin-top: -5px;" v-model="productGrouping" row @change="loadNewGroup">
            <v-radio label="Suppliers" value="supplier"/>
            <v-radio label="Brands" value="brand"/>
          </v-radio-group>
          <h3>{{productGrouping==="supplier"?'Suppliers ('+suppliers.length+')':'Brands ('+brands.length+')'}}</h3>
          <v-text-field outlined :label="'Search '+(productGrouping==='supplier'?'Suppliers':'Brands')" v-model="groupSearch" @input="searchGroup"/>
          <div style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
            <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between" v-for="item in filteredGroup" :key="item.id">
              <span>{{productGrouping==="supplier"?item.name:item.name}}</span>
              <v-btn @click="loadProductsFromGroup(item)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="10">
          <v-card>
            <v-card-title>
              {{tableTitle}}
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                style="margin-bottom: 100px;"
                :headers="headers"
                :items="products"
                :items-per-page="itemsPerPage"
                :search="search"
            >
              <template v-slot:item.name="props">
                <v-edit-dialog
                    :return-value.sync="props.item.name"
                    large
                    persistent
                    @save="saveName(props)"
                    @cancel="cancel"
                >
                  <div>{{ props.item.name }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Name
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.name"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-if="isAllowed('product', 'viewCostPrice')" v-slot:item.cost_price="props">
                <v-edit-dialog
                    :return-value.sync="props.item.cost_price"
                    large
                    persistent
                    @save="saveCostPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ props.item.cost_price }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Cost Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.cost_price"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.regular_price="props">
                <v-edit-dialog
                    :return-value.sync="props.item.regular_price"
                    large
                    persistent
                    @save="saveSellingPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ props.item.regular_price }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Selling Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.regular_price"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.sale_price="props">
                <v-edit-dialog
                    :return-value.sync="props.item.sale_price"
                    large
                    persistent
                    @save="savePromoPrice(props)"
                    @cancel="cancel"
                >
                  <div>{{ props.item.sale_price }}</div>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">
                      Update Prmotion Price
                    </div>
                    <v-text-field
                        @focus="lockGlobalQueryBc"
                        @blur="unlockGlobalQueryBc"
                        v-model.lazy="props.item.sale_price"
                        label="Edit"
                        single-line
                        counter
                        autofocus
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1" v-if="isAllowed('product', 'u')" fab x-small color="info" @click="rowClick(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mr-1" v-if="isAllowed('product', 'editLocations')" :loading="item.loadingLocations" fab x-small color="success" @click="showLocations(item)">
                  <v-icon>mdi-warehouse</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="locationsDialog" width="500">
      <v-card v-if="selectedProduct">
        <v-card-title class="d-flex flex-column">
          <div>
            <span>Location Quantities for</span>
          </div>
          <div class="text-center">
            <h4>{{selectedProduct.name}}</h4>
            <h5>Model #: {{selectedProduct.sku}}</h5>
            <h5>Total In Stock: {{selectedProduct.totalQuantity}}</h5>
            <h5>Available Stock: {{selectedProduct.availableStock}}</h5>
            <h5>Physical Stock: {{selectedProduct.physicalStock}}</h5>
            <h5>New Pending Total: {{calculateTotal()}}</h5>
          </div>
        </v-card-title>
        <v-card-text>
          <div v-for="(location, i) of selectedProduct.locations" :key="i">
            <hr>
            <v-row class="pt-2 px-" style="margin-bottom: -20px;">
              <v-col class="">
                <span><b>{{location.name}}</b></span>
              </v-col>
              <v-col class="d-flex flex-row px-0">
                <span class="d-flex flex-row px-0">
                  <v-text-field :min="0" @change="changeQty(location, 'quantity')" :rules="quantityRules" class="mx-2" label="QTY" v-model="location.quantity" type="number"/>
                </span>
                <span class="d-flex flex-row px-0">
                  <v-text-field :min="0" @change="changeQty(location,'usedQuantity')" :rules="quantityRules" class="mx-2" label="Used QTY" v-model="location.usedQuantity" type="number"/>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" @click="hideLocations" text>Cancel</v-btn>
          <v-btn :loading="locationsDialogPending" color="success" @click="saveProductQuantities">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
<script>
  import axios from 'axios';
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    data () {
      return {
        search: '',
        page: 1,
        pageCount: 0,
        itemsPerPage: -1,
        tableTitle: '',
        tableLoading: false,
        headers: [
          {text: 'DB ID', align: 'start', value: 'id',},
          { text: 'Name', value: 'productData.name' },
          { text: 'SKU', value: 'productData.sku' },
          { text: 'Brand', value: 'brandName' },
          { text: 'Supplier', value: 'supplierName' },
          { text: 'CP', value: 'productData.cost_price' },
          { text: 'SP', value: 'productData.regular_price' },
          { text: 'PP', value: 'productData.sale_price' },
          { text: 'Actions', value: 'actions'},
        ],

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        loader: true,
        searchTerm: '',
        singular: "Product",
        singularLower: "product",
        plural: "Products",
        pluralLower: "products",
        formRules: {
          requiredRule: [v => !!v || 'This field is required.'],
          max25chars: [v => v.length <= 25 || 'Input too long!'],
          emailRules: [
            v => !!v || 'This field is required.',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid.',
          ],
          phoneRules: [
            v => !!v || 'This field is required.',
            v => (v && v.length == 7) || 'Phone must be 7 characters.',
            v => /\d\d\d\d\d\d\d/.test(v) || 'Phone number cannot contain letters.',
          ],
        },

        quantityRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],

        products: [],
        suppliers: [],
        brands: [],
        warehouses: [],

        groupSearch: "",
        filteredGroup: [],
        productGrouping: "supplier",

        locationsDialog: false,
        selectedProduct: null,
        locationsDialogPending: false
      }
    },
    async mounted(){
      try {
        let res = await axios.get(`${this.getEndpoint}/api/suppliers`);
        if(res.data.error) throw res.data.error
        this.suppliers = res.data.data;

        res = await axios.get(`${this.getEndpoint}/api/brands`);
        if(res.data.error) throw res.data.error
        this.brands = res.data.data

        if(!this.isAllowed('product', 'viewCostPrice')){
          let c = this.headers.findIndex(x=>x.text =='CP')
          this.headers.splice(c,1)
        }
        if(!this.isAllowed('product', 'editLocations')){
          let c = this.headers.findIndex(x=>x.text =='Actions')
          this.headers.splice(c,1)
        }

        let wh = await axios.get(`${this.getEndpoint}/api/warehouses/withRooms`);
        if(wh.data.error) throw wh.data.error
        this.warehouses = wh.data.data;

        if(this.$route.query.supplier){
          let id = parseInt(this.$route.query.supplier);
          let supplier = this.suppliers.find(x => x.id===id);
          if(supplier){
            this.productGrouping = "supplier";
            this.filteredGroup = this.suppliers;
            await this.loadProductsFromGroup(supplier)
          }
        }
        else if(this.$route.query.brand){
          let id = parseInt(this.$route.query.brand);
          let brand = this.brands.find(x => x.id===id);
          if(brand){
            this.productGrouping = "brand";
            this.filteredGroup = this.brands;
            await this.loadProductsFromGroup(brand)
          }
        }
        else{
          this.filteredGroup = this.suppliers;
          res = await axios.get(`${this.getEndpoint}/api/products/findallshallow/20`);
          if(res.data.error) throw res.data.error
          this.loadProducts(res.data.data)
        }

      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }finally{
        this.loader = false
      }
    },
    computed: {
      ...mapGetters(['getUsername', 'getEndpoint', 'isAllowed',])
    },
    methods: {
      ...mapMutations([
        'lockGlobalQueryBc',
        'unlockGlobalQueryBc',
      ]),
      loadProducts(prods, item=null){
        this.products = prods
        if(item) this.tableTitle = `Showing ${prods.length} Products from ${this.productGrouping==='supplier'?"Supplier":"Brand"}: ${this.productGrouping==='supplier'?item.name:item.name}`
        else this.tableTitle = `Showing First ${prods.length} Products`

        this.products.forEach(p=>{
          if(!p.brandId) p.brandName = '-'
          else p.brandName = this.brands.filter(x=>x.id==p.brandId)[0].name

          if(!p.supplierId) p.supplierName = '-'
          else p.supplierName = this.suppliers.filter(x=>x.id==p.supplierId)[0].name
        })
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async saveName(e) {
        try {
          e
          throw 'Feature Locked'
          // let res = await axios.put(`${this.getEndpoint}/api/products/updateName`, {pId: e.item.id, productData: e.item});
          // if(res.data.success){
          //   this.snack('Product Saved')
          // }else{throw 'Prodc'}
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async saveSellingPrice(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updateSellingPrice`, {pId: e.item.id, newPrice: e.item.regular_price});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async savePromoPrice(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updatePromoPrice`, {pId: e.item.id, newPrice: e.item.sale_price});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async saveCostPrice(e) {
        try {
          let res = await axios.put(`${this.getEndpoint}/api/products/updateCostPrice`, {pId: e.item.id, newPrice: e.item.cost_price});
          if(res.data.error) throw res.data.error
          this.snack('Product Saved')
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async saveProductQuantities(){
        try{
          this.locationsDialogPending = true;
          if(!this.selectedProduct) throw "No product selected."

          let obj = {
            id: this.selectedProduct.id,
            type: this.selectedProduct.type,
            locations: this.selectedProduct.locations
          }

          let res = await axios.post(`${this.getEndpoint}/api/products/updateLocations`, obj);
          if(res.data.error) throw res.data.error

          this.snack("Quantities Updated")
          this.hideLocations();
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
        finally{
          this.locationsDialogPending = false;
        }
      },
      cancel() {
        this.snack('Cancelled')
      },
      calculateTotal(){
        let total = 0;

        for(let location of this.selectedProduct.locations){
          total+=location.quantity+location.usedQuantity;
        }

        return total;
      },
      async showLocations(item){
        try{
          item.loadingLocations = true;
          this.$forceUpdate();

          let locations = [];
          for(let warehouse of this.warehouses){
            for(let room of warehouse.Rooms){
              let obj = {
                roomId: room.id,
                name: warehouse.name+' '+room.name,
                quantity: 0,
                usedQuantity: 0
              }
              locations.push(obj);
            }
          }
          item.totalQuantity = 0;
          item.locations = locations;

          let prj = await axios.get(`${this.getEndpoint}/api/products/${item.id}/onlyLocations`);
          if(prj.data.error) throw prj.data.error

          let rooms = prj.data.data.ProductRoomJoins;
          for(let room of rooms){
            let found = item.locations.find(x => x.roomId===room.roomId);
            if(found){
              found.quantity = room.quantity;
              found.usedQuantity = room.usedQuantity;
              item.totalQuantity+=room.quantity+room.usedQuantity;
            }
          }

          this.selectedProduct = item;

          this.locationsDialog = true;
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
        finally{
          item.loadingLocations = false;
          this.$forceUpdate();
        }
      },
      hideLocations(){
        this.selectedProduct = null;
        this.locationsDialog = false;
      },
      changeQty(location, field='quantity'){
        if(location[field]>=0){
          location[field]=parseInt(location[field]);
          this.$forceUpdate();
        }
      },
      async loadProductsFromGroup(item){
        try {
          this.tableLoading = true

          let group = this.productGrouping==='supplier'?'Supplier':'Brand';

          let res = await axios.get(`${this.getEndpoint}/api/products/by${group}NoVariations/${item.id}`);
          if(res.data.error) throw res.data.error

          if(this.productGrouping==="supplier"){
            if(!(this.$route.query.supplier && this.$route.query.supplier==item.id)){
              await this.$router.replace({path: `/products`, query:{supplier: item.id}});
            }
          }
          else if(this.productGrouping==="brand"){
            if(!(this.$route.query.brand && this.$route.query.brand==item.id)){
              await this.$router.replace({path: `/products`, query:{brand: item.id}});
            }
          }

          this.loadProducts(res.data.data, item)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.tableLoading = false
        }
      },
      rowClick(row){
        this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
      },
      loadNewGroup(){
        this.filteredGroup = this.productGrouping==='supplier'?this.suppliers:this.brands;
      },
      searchGroup(){
        let arr = this.productGrouping==='supplier'?this.suppliers:this.brands;
        let term = this.productGrouping==='supplier'?'name':'name';

        if(this.groupSearch.length===0){
          this.filteredGroup = arr;
          return;
        }

        this.filteredGroup = arr.filter(item => {
          return item[term].toLowerCase().includes(this.groupSearch.toLowerCase());
        });
      }
    }
  }
</script>
